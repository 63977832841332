//Screen sizes
$breakpoints: ( sm: '(max-width: 599px)',  md: '(min-width: 600px)');
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content; } }

//fonts
$font_title: 'Pacifico', 'Tourney', 'Crimson Text', cursive;
$font_main: "Sawarabi Gothic", cursive;

.font_seisankun {
  font-family: "M PLUS Rounded 1c"; }

.font_veggie {
  font-family: 'Kaushan Script', cursive; }


//colors
$basic_green: #145A32;
$basic_background: #faf6f1;
$blue: #06b0d2;
$skyblue: #5bd1d7;
$darkblue: #126074;
$green: #8cc4b0;
$red: #f05167;


//selectors
@for $i from 1 through 3 {
  h#{$i} { margin: 0px; display: inline-block; font-weight: normal;}; }

p {
  margin: 0px;
  display: inline-block; }

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none; }
  &:visited {
    color: inherit;
    text-decoration: none; } }
