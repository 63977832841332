@import "./common.sass";


#mainpage {
  width: 100%;
  height: auto;
  background-color: $basic_background;
  font-family: $font_main;
  font-size: clamp(10px, 2vw, 15px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  #front {
    width: 100%;
    height: 110vh;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    .title {
      color: white;
      font-family: $font_title;
      font-size: clamp(16px, 10vw, 150px);
      width: 100%;
      margin: 0px;
      margin-top: 10px;
      text-align: center;
      z-index: 5; }
    nav {
      color: $darkblue;
      width: 100%;
      margin-right: 10%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      z-index: 5;
      a {
        font-family: 'Poppins', sans-serif;
        font-size: clamp(20px, 2vw, 20px);
        margin-left: 10px; } }
    .selected {
      color: white; }
    .movingsvg {
      background-color: $blue;
      position: absolute;
      width: 100%;
      height: 110vh; } }
  #contents {
    width: 100%;
    margin-bottom: 15%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column; }
  .content {
    width: 100%;
    margin-top: clamp(10px, 10vw, 100px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &--front {
      @extend .content;
      margin-top: clamp(10px, 10vw, 50px); }
    &--standard {
      @extend .content;
      width: clamp(300px, 60%, 500px); }
    h1 {
      font-family: $font_title;
      width: 100%; }
    &__left {
      width: 50vw;
      height: 50vw;
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
    .image {
      width: clamp(100px, 90%, 500px);
      height: clamp(100px, 90%, 500px);
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      .picture {
        margin-left: 5%;
        width: 90%;
        height: 90%; } }
    &__right {
      width: 50vw;
      height: 50vw; } }
  footer {
    margin-bottom: 20px; } }
