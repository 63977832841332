$blue: #5bd1d7;

body {
  margin: 0px; }

.app {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #34241e;
  background-color: #fffff9;
  font-family: "M PLUS Rounded 1c"; }
